<template>
  <div class="classify">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div id="dataList" slot="scrollList">
        <van-sticky>
          <!--搜索-->
          <search :search-out="searchOut" />
          <!--导航 4 个-->
          <filterTabs :options="options" />
        </van-sticky>
        <!--循环 list -->
        <div v-for="(ele, index) in shoplist" :key="index" class="shoplist" @click="godtal(ele)">
          <imgLoading :src="ele.productImage" class="img" />
          <div class="shoptext">
            <div class="shoptitle">{{ ele.productName }}</div>
            <div>
              <div>
                <span style="color: red; font-size: 10px">￥</span>
                <span style="color: red; font-size: 18px">{{ ele.vipPrice }}</span>
                <span>￥{{ ele.price }}</span>
              </div>
              <div>热销{{ ele.orderPayCount }}件</div>
            </div>
          </div>
        </div>
      </div>
    </mescroll>
    <!--筛选-->
    <rightSidebar :data-sxlist="dataSxlist" />
  </div>
</template>

<script>
/* eslint-disable eqeqeq */

import filterTabs from '@/component/filterTabs/index'
import search from '@/component/head/search'
import mescroll from '@/component/fresh_scroll/mescroll'
import imgLoading from '@/component/imgLoading'
import rightSidebar from '@/component/filterTabs/rightSidebar'
import { optionskeyword, getscreen } from '@/services/api'

export default {
  name: 'ClassificationOfGoodsDetails',
  // import引入的组件需要注入到对象中才能使用
  components: {
    search,
    filterTabs,
    mescroll,
    imgLoading,
    rightSidebar
  },
  data() {
    // 这里存放数据
    return {
      dataSxlist: {
        dataList: '', //  侧边栏 接口 数据
        isShow: false, // 侧边栏 显示隐藏
        btnDetermine: this.btnDetermine // 点击确定回调
      },
      copylist: [],
      options: {
        active: 0,
        activeChange: (index) => {
          this.parmas.page = 1
          this.sxparmas.sorttype = index + 1
          this.parmas.sorttype = index + 1
          optionskeyword(this.parmas).then((res) => {
            this.shoplist = res.data.doc
          })
        },
        tabsList: [
          {
            title: '综合',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '销量',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '价格',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '筛选',
            filter: {
              showFilter: true,
              filterChange: () => {
                // this.show = true;
                this.dataSxlist.isShow = true
              }
            }
          }
        ]
      },
      // 又来承接的数组
      orderlist: [],
      didx: 1,
      idx: '',
      sxlist: '',
      value1: '',
      value2: '',
      pdsolt: 0,
      shoplist: [],
      show: false,
      type: true,
      sortType: 0,
      titlelist: ['综合', '销量', '价格', '筛选'],
      searchOut: {
        titleData: '', // 设置标题 title 名字
        bgColor: '#ffffff', // 设置导航背景颜色
        urlImg: '', // 点击返回图片 可以设置
        isShowReturn: true, // 点击返回图片 显隐
        textColor: '#000',
        callbackGo: this.callbackGo // 返回回调函数
      },
      // 列表需要的参数
      parmas: {
        indexKey: '',
        channelId: 'all',
        categoryFirstId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10
      },
      sxparmas: {
        indexKey: '',
        channelId: 'all',
        categoryFirstId: '',
        categorySecondId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10,
        minprice: '',
        maxprice: '',
        brandId: ''
      },
      mescrollDown: {
        use: true,
        bottom: '0'
      },
      mescrollUp: {
        use: true,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    document.title = this.$route.query.keyword
    this.searchOut.titleData = this.$route.query.keyword
    // 判断的是商品ID
    if (this.$route.query.brand == 1) {
      this.parmas.indexKey = 'brandId'
      this.sxparmas.indexKey = 'brandId'
      this.parmas.categoryThreeId = ''
      this.parmas.brandId = this.$route.query.goodTypeId
    } else {
      // 品牌ID
      this.parmas.indexKey = 'categoryThreeId'
      this.sxparmas.indexKey = 'categoryThreeId'
      this.parmas.categoryThreeId = this.$route.query.goodTypeId
    }
    this.parmas.keyword = this.$route.query.keyword
    getscreen(this.parmas).then((res) => {
      this.dataSxlist.dataList = res.data
    })
  },
  methods: {
    // 侧边栏 回调
    btnDetermine: function(data) {
      const dataList = {
        indexKey: 'categoryThreeId',
        channelId: 'all',
        categoryFirstId: '',
        categoryThreeId: this.$route.query.goodTypeId,
        keyword: this.$route.query.keyword,
        sorttype: data.sorttype, //  1 全部  2 销量  3 价格
        sort: data.sort, // 1价格正序  0 倒序
        page: data.page,
        pageSize: data.pageSize,
        minprice: data.minprice,
        maxprice: data.maxprice
      }
      optionskeyword(dataList).then((res) => {
        this.shoplist = res.data.doc
      })
    },
    // 返回
    callbackGo() {
      this.$router.go(-1)
    },
    // 上拉滑拽
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.shoplist = []
          // 把请求到的数据添加到列表
          this.shoplist = this.shoplist.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            // let number=0
            // if(arr.length>0){
            //   number=11
            // }
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const that = this
      that.parmas.page = pageNum
      that.parmas.pageSize = pageSize

      optionskeyword(that.parmas).then((res) => {
        successCallback(res.data.doc)
      })
    },
    // 跳详情
    godtal(ele) {
      const obj = {
        'product_type': ele.product_type,
        'goodId': ele.productId,
        'skuId': ele.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    // tab 切换
    typeChange(type) {
      if (type == 'add') {
        this.parmas.sort = 1
      } else {
        this.parmas.sort = 0
      }
      optionskeyword(this.parmas).then((res) => {
        this.shoplist = res.data.doc
      })
    }
  }

}
</script>
<style lang="less" scoped>

* {
  text-align: left;

  h1 {
    font-size: 12px;
    padding-left: 10px;
  }

}

#dataList {
  background: #f6f6f6;
}

.pxarrow {
  width: 12px;
  height: 16px;
  margin-left: 2px;
}

.shoplist {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
  background: #fff;

  .img {
    width: 100px;
    height: 100px;
  }

  .shoptext {
    padding-left: 10px;
    width: 250px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;

    div {
      font-size: 10px;
    }

    .shoptitle {
      line-height: 20px;
      width: 100%;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.zzbox {
  padding-top: 25px;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .btnbox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;

    button {
      width: 45%;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 15px;
    }

    .btn2 {
      color: #ffffff;
      background: red;
    }
  }

  h1 {
    margin: 5px 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5px;

    .lis {
      margin-top: 5px;
      margin-left: 3%;
      height: 25px;
      line-height: 25px;
      background: #f8f8f8;
      border-radius: 15px;
      text-align: center;
      width: 28%;
      padding: 0 10px;
      font-size: 12px;
    }

    .li {
      width: 33%;
      margin-top: 5px;
      // margin-left: 3%;
      height: 25px;
      line-height: 25px;
      background: #f8f8f8;
      border-radius: 15px;
      text-align: center;
      font-size: 12px;

      span {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        transform: scale(0.6);
      }
    }

    .bgipt {
      color: rgb(236, 185, 185);
      background: rgb(250, 238, 238);
      border: 1px solid rgb(236, 185, 185);
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .ipt {
    width: 100px;
    border-radius: 10px;
    height: 25px;
    background: #f8f8f8;
    text-align: center;
  }

  div {
    width: 20px;
    height: 1px;
    background: #eeeeee;
  }
}

</style>

